import React from "react";

const AppFooter = () => {
  return (
    <div className="App-footer">
      <div>
        <div>100B</div>
        <div>Supply</div>
      </div>
      <div>
        <div>5%</div>
        <div>Buy Tax</div>
      </div>
      <div>
        <div>5%</div>
        <div>Sell Tax</div>
      </div>
      <div>
        <div>Fantom</div>
        <div>Network</div>
      </div>
    </div>
  );
};

export default AppFooter;
