import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { useEffect, useState } from "react";

const HomePage = () => {
  useEffect(() => {}, []);

  return (
    <div className="home-container">
      <div className="bg-image" />
      <div class="top">
        <img src="images/top.png" alt="" />
      </div>

      <div className="intro">
        <div className="left">
          <div className="project-name">
            <h2>CONKE</h2>
          </div>
          <div className="launchpad-title">MAKE FANTOM GREAT AGAIN</div>
          <div className="content">
            Conke, a duck with a deep passion and knowledge about the Crypto
            world, is a unique character who brings a delicate combination of
            Meme and blockchain technology. With bright blue skin, Conke not
            only attracts attention for her lovely appearance but also for her
            in-depth research and understanding of blockchain. Conke is not just
            an ordinary duck, but also a talented researcher in the field of
            blockchain technology. With in-depth knowledge and a keen
            understanding of how this technology can change the world of
            finance, Conke is determined to use all his knowledge and skills to
            improve the world of Crypto, especially on Fantom platform.
          </div>
          <div className="socials">
            <button
              onClick={() => {
                window.open("https://t.me/conkeftm", "_blank");
              }}
            >
              <FaTelegram className="social-icon" />
              <span>Telegram</span>
            </button>
            <button
              onClick={() => {
                window.open("https://twitter.com/conke_ftm", "_blank");
              }}
            >
              <FaTwitter className="social-icon" />
              <span>Twitter</span>
            </button>
          </div>
        </div>
        <div className="avatar-container">
          <div className="glow" />
          <img
            id="avatar"
            className="avatar zoom-effect"
            src="images/2.png"
            alt=""
          />
        </div>
      </div>

      <div className="body-content-1">
        <div className="body-img body-img-1">
          <img src="images/conke-btc.jpeg" alt="" />
        </div>
        <div className="content-1">
          This Crypto duck sets a great goal: turning Fantom into the largest
          Crypto ecosystem, bringing it to new heights in the world of digital
          finance. Inspired by the dream of transformation, Conke not only
          wanted to be an excellent individual researcher but also wanted to
          become a leader, guiding the community in the direction of growth and
          creativity.
        </div>
      </div>

      <div className="body-content-2">
        <div className="content-2">
          With confidence, knowledge, and an interesting love for the Crypto
          world, Conke is not only a character with a unique personality but
          also a source of encouragement for all of us, encouraging everyone to
          dream and follow. Chase your dreams in the exciting world of
          blockchain and cryptocurrency.
        </div>
        <div className="body-img body-img-2">
          <img src="images/conke-working.jpeg" alt="" />
        </div>
      </div>

      <div className="swap-widget" style={{ fontSize: 18 }}>
        <div style={{ marginBottom: 16 }}>
          <span>View chart on Dexscreener:</span>
          <a
            href="https://dexscreener.com/fantom/0x881b5aa7cdee847c54031cd57d241ff4b8a2366b"
            target="blank"
            style={{ marginLeft: 8 }}
          >
            https://dexscreener.com/fantom/0x881b5aa7cdee847c54031cd57d241ff4b8a2366b
          </a>
        </div>
        <div>
          <span>Trade on SpookySwap:</span>
          <a
            href="https://spooky.fi/#/swap?outputCurrency=0x650fBfcA374276bc375d97b970fAdFcA6a28d8e9&inputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83"
            target="blank"
            style={{ marginLeft: 8 }}
          >
            https://spooky.fi/#/swap?outputCurrency=0x650fBfcA374276bc375d97b970fAdFcA6a28d8e9
          </a>
        </div>

        <iframe
          style={{
            width: "100%",
            height: 500,
            marginTop: 90,
            marginBottom: 64,
          }}
          src="https://dexscreener.com/fantom/0x881b5aa7cdee847c54031cd57d241ff4b8a2366b"
          title="CONKE"
        />
      </div>
    </div>
  );
};

export default HomePage;
