import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaEthereum } from "react-icons/fa";

export const menuItemsData = [
  {
    itemTitle: "About",
    subItems: [
      {
        title: "Telegram",
        url: "https://t.me/conkeftm",
        icon: FaTelegram,
        newTab: true,
      },
      {
        title: "Twitter",
        url: "https://twitter.com/conke_ftm",
        icon: FaTwitter,
        newTab: true,
      },
    ],
  },
  {
    itemTitle: "Token Info",
    subItems: [
      {
        title: "FTM Scan",
        url: "https://ftmscan.com/address/0x650fBfcA374276bc375d97b970fAdFcA6a28d8e9",
        icon: FaEthereum,
        newTab: true,
      },
      {
        title: "Dex Screener",
        url: "https://dexscreener.com/fantom/0x881b5aa7cdee847c54031cd57d241ff4b8a2366b",
        icon: FaEthereum,
        newTab: true,
      },
    ],
  },
];
