import React, { useEffect, useRef, useState } from "react";
import AppMenu from "../AppMenu";
import { FiMenu } from "react-icons/fi";
import AppMenuItem from "../AppMenu/AppMenuItem";
import { Styles } from "../../Styles";
import PersistentDrawerRight from "../Drawer";
import { IconButton } from "@mui/material";

/**
 * Hook that alerts clicks outside of the passed ref
 */
// function useOutsideAlerter(ref) {
//   useEffect(() => {
//     /**
//      * Alert if clicked on outside of element
//      */
//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         alert("You clicked outside of me!");
//       }
//     }
//     // Bind the event listener
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [ref]);
// }

const AppHeader = () => {
  const [showVerticalMenu, setShowVerticalMenu] = useState(false);

  return (
    <header className="App-header">
      <div className="logo">CONKE</div>
      <AppMenu />
      <button
        className="btn-buy"
        onClick={() => {
          window.open(
            "https://spooky.fi/#/swap?outputCurrency=0x650fBfcA374276bc375d97b970fAdFcA6a28d8e9&inputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83"
          );
        }}
      >
        BUY
      </button>
      <div className="btn-menu">
        <IconButton aria-label="delete">
          <FiMenu
            style={{
              display: showVerticalMenu ? "none" : "block",
              color: Styles.color,
              fontSize: 32,
            }}
            onClick={() => setShowVerticalMenu(true)}
          />
        </IconButton>

        <div
          style={{
            position: "absolute",
            right: 0,
            background: Styles.colorRgba,
            display: showVerticalMenu ? "block" : "none",
            transition: "0.5s",
          }}
        >
          <AppMenu vertical />
        </div>
        <PersistentDrawerRight
          open={showVerticalMenu}
          setOpen={setShowVerticalMenu}
        />
      </div>
    </header>
  );
};

export default AppHeader;
